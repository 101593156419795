<template>
  <div class="flex flex-col rounded-md shadow-lg overflow-hidden">
    <div class="flex-shrink-0">
      <img
        v-if="imageUrl" style=" height: 20rem; "
        class="w-full object-cover"
        :src="imageUrl"
        alt=""
      />
    </div>
    <div class="bg-white p-4 flex flex-col h-full">
      <p class="text-lg font-semibold text-gray-900 capitalize">
        <slot> </slot>
      </p>
      <router-link
        v-if="href"
        :to="href"
        class="text-accent with-underline mt-1 w-max"
        >View</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      required: false,
      type: String,
    },
    href: {
      required: false,
      type: String,
    },
  },
};
</script>
