<template>
  <Nav />
  <router-view />
  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
import Nav from "@/components/Nav.vue";

export default {
  components: { Nav, Footer },
};
</script>
